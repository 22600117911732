import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reserve_services: [],
    line_uuid: null,
  },
  getters: {},
  mutations: {},
  actions: {
    addReserveOrder(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/frontend/reserve-order', itemData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
