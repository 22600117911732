import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      // redirect: '/rBjfFnEC',
      redirect: { name: 'apps-reserve-v2-list', params: { slug: 'rBjfFnEC' } },
    },
    /**
     * v1
     */
    // {
    //   path: '/:slug',
    //   name: 'apps-reserve-list',
    //   component: () => import('@/views/v1-reserve/reserve-list/ReserveList.vue'),
    // },

    // {
    //   path: '/:slug/pa/:code',
    //   name: 'apps-reserve-page',
    //   component: () => import('@/views/v1-reserve/reserve-page/ReservePage.vue'),
    // },

    // 預約訂單
    // {
    //   path: '',
    //   component: () => import('@/views/reserve-order/ReserveOrderIndex.vue'),
    //   meta: { layout: 'full', resource: 'Auth', filter: true },
    //   children: [
    //     {
    //       path: '/liff/:slug/reserve-order',
    //       name: 'reserve-order-page',
    //       component: () => import('@/views/reserve-order/ReserveOrder.vue'),
    //       meta: { layout: 'full', resource: 'Auth', filter: true },
    //     },
    //   ],
    // },

    /**
     * v2
     */
    {
      path: '',
      component: () => import('@/views/v2-reserve/ReserveIndex.vue'),
      children: [
        {
          path: '/v2/:slug',
          name: 'apps-reserve-v2-list',
          component: () => import('@/views/v2-reserve/reserve-list/ReserveList.vue'),
        },
        {
          path: '/v2/:slug/pa/:code',
          name: 'apps-reserve-v2-page',
          component: () => import('@/views/v2-reserve/reserve-page/ReservePage.vue'),
        },
        {
          path: '/v2/:slug/pa/:code/submit',
          name: 'apps-reserve-v2-submit',
          component: () => import('@/views/v2-reserve/reserve-submit/ReserveSubmit.vue'),
        },
        {
          path: '/v2/:slug/order-received/:serial_no',
          name: 'apps-reserve-v2-received',
          component: () => import('@/views/v2-reserve/reserve-order/ReserveOrder.vue'),
        },
      ],
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
